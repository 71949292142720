import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import paymentslogo from "../../assets/payments.png";
import { products } from "../../products/products";
import gpay from "../../assets/gpay.jpg";
import paytm from "../../assets/paytm.png";
import phonepe from "../../assets/phonepe.png"
import upilogo from "../../assets/upilogo.png"
import paymentsecured from "../../assets/paymentsecured.png"
import { Divider, Typography } from "@mui/material";
import {paymentDetails} from "../../upistore/upistore"
const Payment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [timeLeft, setTimeLeft] = useState(600000);

  const [selected,setSelected]=useState("gpay")

  useEffect(() => {
    const findProduct = () => {
      const data = products.find((product) => product._id === id);
      if (data) {
        setProduct(data);
      }
    };
    findProduct();
  }, [id]);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);
  const handleBuyClick = (price) => {
    if(selected==="gpay"){
      if (!window.PaymentRequest) {
        return;
      }
  
      const supportedInstruments = [
        {
          supportedMethods: ["https://tez.google.com/pay"],
          data: {
            pa: paymentDetails.gpay,
            pn: "Flipkart Payments",
            tr: "1234ABCD",
            url: "https://yourwebsite.com/order/1234ABCD",
            mc: "1234",
            tn: "Paying to Flipkart",
          },
        },
      ];
  
      const details = {
        total: {
          label: "Total",
          amount: {
            currency: "INR",
            value: price,
          },
        },
        displayItems: [
          {
            label: "Original Amount",
            amount: {
              currency: "INR",
              value: price,
            },
          },
        ],
      };
  
      const request = new PaymentRequest(supportedInstruments, details);
      request.show();
    }
    else if(selected==="paytm"){
      window.location.href=`paytmmp://cash_wallet?pa=${paymentDetails.paytm}&pn=Flipkart&am=${price}.00&tr=AkBIIGH2MkMGf5olejI&mc=8931&cu=INR&tn=Paying_to_Flipkart&sign=AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr&featuretype=money_transfer`
    }
    else if(selected==="phonepe"){
        window.location.href=`phonepe://pay?ver=01&mode=19&pa=${paymentDetails.phonepe}&pn=Flipkart&tr=RZPP17BnFfW1AbdRqqrv2&cu=INR&mc=5399&qrMedium=04&tn=Payment%20to%20%20Flipkart&am=${price}`
      }
      else if(selected==="upi"){
        window.location.href=`upi://pay?ver=01&mode=19&pa=${paymentDetails.upi}&pn=Flipkart&tr=RZPP17BnFfW1AbdRqqrv2&cu=INR&mc=5399&qrMedium=04&tn=Payment%20to%20%20Flipkart&am=${price}`
      }
   
   
  };

  return (
    product && (
      <div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <KeyboardBackspaceIcon onClick={() => navigate("/")} />
          <span>Order Summary</span>
        </div>
        <img src={paymentslogo} alt="address" style={{ width: "100%" }} />

        <div
          style={{
            backgroundColor: "white",
            textAlign: "center",
            padding: "15px",
            borderBottom: "1px solid #E7E7E7",
            borderTop: "1px solid #E7E7E7",
          }}
        >
          <span>
            Offer ends in {"    "}
            <span style={{ color: "#FB641B" }}>
              {minutes}min {seconds}sec
            </span>
          </span>
        </div>
         
        <div
          style={{
            padding: "20px",
            display:"flex",
            flexDirection:"column",
            gap:"10px"
          }}
        >
           
          <div
            onClick={()=>setSelected("gpay")}
            style={{
              border: selected==="gpay"?"1px solid blue":"2px solid #E7E7E7",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent:"center"
            }}
          >
            <img
              src={gpay}
              alt="gpay"
              style={{ width: "30px", height: "26px" }}
            />{" "}
            <span style={{fontWeight:600}}>Google Pay</span>
          </div>
          <div
          onClick={()=>setSelected("paytm")}
            style={{
              border: selected==="paytm"?"1px solid blue":"2px solid #E7E7E7",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              gap: "7px",
              justifyContent:"center"

            }}
          >
            <img
              src={paytm}
              alt="paytm"
              style={{ width: "50px", height: "16px" }}
            />{" "}
            <span style={{fontWeight:600}}>Paytm</span>
          </div>
        {/* <div
            onClick={()=>setSelected("phonepe")}
            style={{
              border: selected==="phonepe"?"1px solid blue":"2px solid #E7E7E7",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              gap: "7px",
              justifyContent:"center"

            }}
          >
            <img
              src={phonepe}
              alt="phonepe"
              style={{ width: "80px", height: "26px" }}
            />{" "}
            <span style={{fontWeight:600}}>Phonepe</span>
          </div>
          <div
            onClick={()=>setSelected("upi")}
            style={{
              border: selected==="upi"?"1px solid blue":"2px solid #E7E7E7",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              gap: "7px",
              justifyContent:"center"

            }}
          >
            <img
              src={upilogo}
              alt="upi"
              style={{ width: "50px", height: "16px" }}
            />{" "}
            <span style={{fontWeight:600}}>All UPI</span>
          </div> */}
        </div>
        <div
              style={{
                backgroundColor: "white",
                textAlign: "left",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
              }}>
              <span style={{fontWeight:600,fontSize:"15px"}}>Price Details:</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Price (1 item)</span>
                <span> ₹{product?.mrp}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Discount</span>
                <span style={{ color: "green" }}>
                  {" "}
                  -{product?.mrp - product?.cost }
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Delivery Charges</span>
                <span style={{ color: "green" }}> FREE Delivery</span>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  padding: "2px",
                }}>
                <span>Total Amount</span>
                <span style={{ color: "green" }}>
                  {" "}
                  ₹{product?.cost }
                </span>
              </div>
              <Divider />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span style={{ color: "green" }}>
                  You will save ₹{product?.mrp - product?.cost } on
                  this order
                </span>
              </div>
            </div>
            <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 999,
              backgroundColor: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px -2px 1px, rgba(0, 0, 0, 0.05) 0px -4px 2px, rgba(0, 0, 0, 0.05) 0px -8px 4px, rgba(0, 0, 0, 0.05) 0px -16px 8px, rgba(0, 0, 0, 0.05) 0px -32px 16px",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              justifyContent: "space-between",
            }}>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "#ABABAA",
                }}>
                ₹{product?.mrp}
              </span>
              <span
                style={{
                  color: "black",
                }}>
                ₹{product?.cost}
              </span>
            </Typography>
            <button
              onClick={()=>handleBuyClick(product?.cost)}
              style={{
                height: "100%",
                padding: "15px 70px",
                backgroundColor: "#ffd814",
                letterSpacing: "0.5px",
                fontSize: "14px",
                color: "#0f1111",
                border: "none",
                outline: "none",
                width: "auto",
                borderRadius: "4px",
                marginRight:"30px",
                fontWeight:600
              }}>
              Pay Now
            </button>
          </div>
        <img src={paymentsecured} alt="akfk" style={{width:"100%"}} />

      </div>
    )
  );
};

export default Payment;
